/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

function HomeHero() {
	return (
		<div>
			<div className="IndexHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Bryan O'Connor</h1>
				<h1 className="dancing-font hero-banner-text orange-text">Principal Engineer</h1>
			</div>
		</div>
	);
}

function HomeContent() {
	return(
		<div>
			<div className="d-block py-0 py-md-5 row text-center">
				<h1 className="main-heading montserrat-font">Exploring New Engineering Opportunities</h1>
				<p className="xlg-paragraph lg-margin xl-padding">
					As a Principal Software Engineer and Solutions Architect, I’ve had the privilege of working on complex systems that drive meaningful impact. 
					From designing scalable architectures to improving system performance and reducing technical debt, I focus on creating solutions that are both effective and sustainable.
				</p>
				<p className="xlg-paragraph lg-margin xl-padding">
					I’m looking for opportunities where I can contribute to building robust systems, mentor teams, and collaborate on innovative projects. 
					I enjoy tackling tough challenges, simplifying complexity, and delivering results that align with business goals. 
					If you're looking for someone who values clean code, strategic thinking, and teamwork, let’s connect!
				</p>
				<div className="text-center">
					<a href="http://35.95.172.172/" target="_blank" rel="noopener noreferrer">
						<button className="lg-button orange-button lg-padding mb-5">Demo App</button>
					</a>
				</div>
			</div>
		</div>
	);
}

function HomeLogos() {
	return(
		<div>
			<div className="mx-auto my-0 row w-75 py-5">
				<div className="col-12 text-center">
					<h1 className="main-heading montserrat-font">Experience & Clients</h1>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Alphagraphics.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Blue.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Cisco.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Discover.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-DISH.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Matthews.png" className="logo-img" alt="" />
					</div>
				</div>

				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 offset-lg-1 offset-md-1 offset-sm-1 offset-xs-0 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-MDRT.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Tranont.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Trimble.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Vivint.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Zions.png" className="logo-img" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

function CoreSkills() {
	return(
		<div>
			<div className="dark-gray-bg d-block mx-auto py-5 row text-center">
				<h1 className="main-heading montserrat-font white-text">Core Skills</h1>
				<div className="montserrat-font xlg-paragraph xl-padding">
					{/* Software & Architecture */}
					<div className="d-inline-flex mr-3 skill-tag">Software Development</div>
					<div className="d-inline-flex mr-2 skill-tag">Enterprise Architecture</div>
					<div className="d-inline-flex mr-2 skill-tag">Distributed Systems</div>
					<div className="d-inline-flex mr-2 skill-tag">Microservices</div>
					<div className="d-inline-flex mr-2 skill-tag">Event-Driven Design</div>
					<div className="d-inline-flex mr-2 skill-tag">API Development</div>

					{/* Cloud & Infrastructure */}
					<div className="d-inline-flex mr-2 skill-tag">AWS</div>
					<div className="d-inline-flex mr-2 skill-tag">GCP</div>
					<div className="d-inline-flex mr-2 skill-tag">Azure</div>
					<div className="d-inline-flex mr-2 skill-tag">Kubernetes</div>
					<div className="d-inline-flex mr-2 skill-tag">Docker</div>
					<div className="d-inline-flex mr-2 skill-tag">Terraform</div>
					<div className="d-inline-flex mr-2 skill-tag">Serverless Architectures</div>

					{/* DevOps & Security */}
					<div className="d-inline-flex mr-2 skill-tag">DevOps</div>
					<div className="d-inline-flex mr-2 skill-tag">CI/CD Pipelines</div>
					<div className="d-inline-flex mr-2 skill-tag">Observability</div>
					<div className="d-inline-flex mr-2 skill-tag">SRE Practices</div>
					<div className="d-inline-flex mr-2 skill-tag">Infrastructure as Code</div>
					<div className="d-inline-flex mr-2 skill-tag">Zero Trust Security</div>
					<div className="d-inline-flex mr-2 skill-tag">IAM</div>

					{/* Backend Technologies */}
					<div className="d-inline-flex mr-2 skill-tag">Node.js</div>
					<div className="d-inline-flex mr-2 skill-tag">Java</div>
					<div className="d-inline-flex mr-2 skill-tag">Python</div>
					<div className="d-inline-flex mr-2 skill-tag">TypeScript</div>
					<div className="d-inline-flex mr-2 skill-tag">JavaScript</div>
					<div className="d-inline-flex mr-2 skill-tag">C#</div>
					<div className="d-inline-flex mr-2 skill-tag">.NET</div>
					<div className="d-inline-flex mr-2 skill-tag">Express</div>
					<div className="d-inline-flex mr-2 skill-tag">REST APIs</div>
					<div className="d-inline-flex mr-2 skill-tag">GraphQL</div>

					{/* Databases & Storage */}
					<div className="d-inline-flex mr-2 skill-tag">PostgreSQL</div>
					<div className="d-inline-flex mr-2 skill-tag">MySQL</div>
					<div className="d-inline-flex mr-2 skill-tag">MongoDB</div>
					<div className="d-inline-flex mr-2 skill-tag">NoSQL</div>
					<div className="d-inline-flex mr-2 skill-tag">Redis</div>

					{/* Frontend Technologies */}
					<div className="d-inline-flex mr-2 skill-tag">React</div>
					<div className="d-inline-flex mr-2 skill-tag">Vue</div>
					<div className="d-inline-flex mr-2 skill-tag">Angular</div>

					{/* AI & Automation */}
					<div className="d-inline-flex mr-2 skill-tag">AI/ML Workflows</div>
					<div className="d-inline-flex mr-2 skill-tag">LLM Integration</div>
					<div className="d-inline-flex mr-2 skill-tag">AI-Augmented Development</div>
					<div className="d-inline-flex mr-2 skill-tag">MLOps</div>
					<div className="d-inline-flex mr-2 skill-tag">AI-Powered Search (RAG)</div>

					{/* Leadership & Strategy */}
					<div className="d-inline-flex mr-2 skill-tag">Technical Mentorship</div>
					<div className="d-inline-flex mr-2 skill-tag">Engineering Best Practices</div>
					<div className="d-inline-flex mr-2 skill-tag">Product Strategy</div>
					<div className="d-inline-flex mr-2 skill-tag">Stakeholder Collaboration</div>
					<div className="d-inline-flex mr-2 skill-tag">Product Leadership</div>
					<div className="d-inline-flex mr-2 skill-tag">Communication</div>

					{/* Agile & Project Management */}
					<div className="d-inline-flex mr-2 skill-tag">Agile</div>
					<div className="d-inline-flex mr-2 skill-tag">Scrum</div>
					<div className="d-inline-flex mr-2 skill-tag">Jira</div>
					<div className="d-inline-flex mr-2 skill-tag">ServiceNow</div>

				</div>
			</div>
		</div>
	);
}

function AboutContent() {
	return (
		<div>
			<div className="m-0 row">
				<div className="col-12 col-md-2 offset-md-3 offset-0">
					<img className="profile-img" src="https://bdopersonal.s3.us-west-2.amazonaws.com/Bryan+Social+Icon+001.jpg" alt="" />
				</div>
				<div className="col-12 col-md-7 p-2 p-md-0 text-center text-md-left">
					<h2 className="montserrat-font mt-0 mt-md-5">Bryan O'Connor</h2>
					<h4 className="montserrat-font">Principal Engineer & Solutions Architect</h4>
					<p className="m-0"><FontAwesomeIcon icon={faEnvelope} /><span className="pl-2">bryanoconnordev@gmail.com</span></p>
					<p className="m-0"><FontAwesomeIcon icon={faPhone} /><span className="pl-2">385.272.8087</span></p>
					<p className="m-0 pb-4 pb-md-0">
						<FontAwesomeIcon className="fa-icon" icon={faFileArrowDown} />
						<a href="https://bryandoconnor.s3.us-west-2.amazonaws.com/resume/Bryan+O'Connor+Resume+2025+(1).docx" target="_blank" rel="noreferrer">
							<span className="pl-2">Resume</span>
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}

function Home() {
	return (
		<div className="container-fluid p-0">
			<HomeHero />
			<HomeContent />
			<HomeLogos />
			<CoreSkills />
			<AboutContent />
		</div>
	);
}

export default Home;
